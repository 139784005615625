// Libraries
import { useSelector } from 'react-redux';

// Ducks
import { StoreState } from '@/ducks';
import { selectBusinessId } from '@/ducks/user/selectors';

export const useBusinessId = () => {
    return useSelector( ( state: StoreState ) => ( selectBusinessId( state ) ) );
};
